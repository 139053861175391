<template>
  <div class="order-page-container">
    <h2 class="order-page-title">我的订单</h2>

    <el-table :data="list" v-loading="loading">
      <el-table-column label="订单编号"></el-table-column>
      <el-table-column label="商品名称"></el-table-column>
      <el-table-column label="支付金额"></el-table-column>
      <el-table-column label="支付方式"></el-table-column>
      <el-table-column label="支付时间"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
    };
  },
};
</script>

<style lang="less" scoped>
.order-page-container {
  width: 1240px;
  margin: 0 auto;
}
.order-page-title {
  padding: 20px 0;
}
</style>
